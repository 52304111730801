 .section-wraper {
     max-width: 1200px;
     margin-left: auto;
     margin-right: auto;
     width: 100%;
     padding-left: 40px;
     padding-right: 40px;
 }

 .section-wraperA {
     max-width: 1200px;
     margin-left: auto;
     margin-right: auto;
     width: 100%;
 }

 .header-section {
     padding-top: 16px;
     padding-bottom: 16px;
     display: flex;
     justify-content: space-between;
     align-items: center;
 }

 .header-bg {
     background: rgba(255, 255, 255, 0.5);
     backdrop-filter: blur(10px);
 }

 .see-more-btn:hover .see-more-icon {
     width: 28px;
     transition: all 0.2s ease-in-out;
 }

 .digital-suit-logo {
     font-weight: 600 !important;
     font-size: 16px !important;
     line-height: 20px !important;
     text-align: center !important;
     color: #4D30A6 !important;

 }

 .header-nav {
     display: flex;
     align-items: center;
     font-weight: 500;
     font-size: 14px;
     line-height: 24px;
     text-align: center;
     letter-spacing: 0.02em;
     color: #A9A9A9;

 }

 .nav-sign-in {
     display: flex;
     flex-direction: row;
     align-items: flex-start;
     padding: 10px 20px;
     background: #4D30A6;
     border-radius: 8px;
     font-weight: 500;
     font-size: 14px;
     color: #FFFFFF !important;
 }

 .nav-sign-in:hover {
     background-color: #5c37ca;
 }

 h1 {
     font-weight: 600;
     font-size: 50px;
     line-height: 65px;
 }

 h2 {
     font-weight: 600;
     font-size: 44px;
     line-height: 48px;
     letter-spacing: 0.02em;
 }

 h3 {
     font-weight: 500;
     font-size: 28px;
     line-height: 43px;
 }



 .section-para {
     font-weight: 400;
     font-size: 16px;
     line-height: 26px;
     letter-spacing: 0.04em;
     color: #8A8A8A;
 }

 .sec-btn {
     padding: 16px 20px;
     /* box-shadow: 0px 8px 24px rgba(163, 71, 215, 0.5); */
     border-radius: 12px;
     font-weight: 600;
     font-size: 16px;
     min-width: 140px;
 }

 .sec-btn:hover {
     animation: pulse 1s infinite;
     transition: .02s;
 }

 .stat-sub3 {
     font-weight: 500;
     font-size: 22px;
     line-height: 24px;
 }

 .stat-no3 {
     font-size: 56px;
     width: 100%;
 }

 .stat-head3 {
     font-size: 46px;
     font-weight: 600;
     line-height: 56px;

 }

 .shadow3 {
     box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.03);
 }

 .bg-grad-3 {
     background: conic-gradient(from 180deg at 50% -0.05%, #4D30A6 0deg, #5E36D9 360deg);
 }

 .form-error {
     position: absolute;
     bottom: 10px;
     font-size: 12px;
     color: red;
 }

 .successMsg {
     animation: showMsg 1s ease-out;
     transition: .001s;
 }

 @keyframes showMsg {
     0% {
         top: -60px
     }

     60% {
         top: 10px
     }

     60% {
         top: 40px
     }

     
     60% {
        top: 90px
    }

     100% {
         top: 76px
     }
 }

 @keyframes showMsg1 {
    0% {
        top: -200px
    }

    50% {
       top: 30px
   }

    80% {
       top: 60px
   }

    100% {
        top: 50px
    }
}


 @keyframes pulse {
     0% {
         transform: scale(1);
     }

     70% {
         transform: scale(.95);
     }

     100% {
         transform: scale(1);
     }
 }

 .sec1-banner {
     background: #E4C9FF;
     border: 1px solid #000000;
     box-shadow: 10px 10px 0px #000000;
     border-radius: 32px;
     margin-top: 150px;
     position: relative;
 }


 .sec2-card {
     padding: 40px 16px 40px 16px;
 }

 .card-head {
     font-weight: 500;
     font-size: 20px;
 }

 .sec2-card p {
     font-weight: 400;
     font-size: 14px;
     line-height: 24px;
     letter-spacing: 0.03em;
     color: #8A8A8A;

 }

 .sec-sub-head {
     font-weight: 500;
     font-size: 32px;
 }


 .bottom-banner {
     background: linear-gradient(93.38deg, #CF79FF 0%, #4D30A6 26.94%, #47006F 100%);
     background-blend-mode: soft-light;


 }

 .dash1 {
     animation-name: triangle-path-1;
     animation-delay: 0s;

 }

 .contact-banner {
     width: 100%;
     /* background: linear-gradient(95.35deg, #4D30A6 0%, #4E007A 100%); */
     border-radius: 18px;
     height: 382px;

 }

 .contact-head {
     font-weight: 500;
     font-size: 40px;
     color: #FFFFFF;
 }

 .contact-sub {
     font-weight: 400;
     font-size: 16px;
     line-height: 24px;
     letter-spacing: 0.03em;
     /* color: #FFFFFF; */
 }

 .card-head-3 {
     border-radius: 174px;
     background-color: #FAFAFA;
 }

 .contact-us-gradient {

     background: #D8E7FF;
     filter: blur(86px);
 }

 .contact-us-gradient1 {
     background: #FFECD0;
     filter: blur(86px);
 }

 .contact-form {
     background: #FFFFFF;
     box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.2);
     border-radius: 12px;
     padding: 25px 30px;
     padding-top: 36px;
 }

 .contact-form input {
     border: 1px solid #dcd7d7;
     margin-bottom: 30px;
     outline: none;
     border-radius: 4px;
     padding: 8px;
     width: 100%;
 }
 .contact-form select {
    border: 1px solid #dcd7d7;
    margin-bottom: 30px;
    outline: none;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    font-weight: 400;
     font-size: 12px;
     color: #8B8B8B;
}

 .contact-form input:hover {
     border: 1px solid #5929ea;
 }
 .contact-form select:hover {
    border: 1px solid #5929ea;
}

 .contact-form input::placeholder {
     font-weight: 400;
     font-size: 12px;
     color: #8B8B8B;
 }
 .contact-form select::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: #8B8B8B;
}
.contact-form option {
    font-weight: 400;
    font-size: 12px;
    color: #8B8B8B;
}
 .submit-btn {
     background: #4D30A6;
     border-radius: 6px;
     font-weight: 500;
     font-size: 14px;
     text-align: center;
     letter-spacing: 0.04em;
     color: #FFFFFF;
     padding: 10px 20px;
 }

 .submit-btn:hover {
     background: #5c37ca;
 }

 .active {
     font-weight: 500;
     font-size: 14px;
     color: #111111;
 }

 .header-nav a {
     display: block;
 }

 .header-nav a:hover {
     color: #4D30A6;
     font-weight: 500;
 }

 .resp-nav {
     transition: all 0.3s ease-in-out;
     padding-top: 4px;
 }

 .resp-nav li {
     padding-bottom: 8px;
     width: 100%;
 }

 .resp-nav li a {
     display: block;
     padding-bottom: 8px;
     width: 100%;
     color: #5a5555;
 }

 .resp-footer li {
     width: 100%;
     background-color: #ededed;
     font-size: 12px;
     font-weight: 500;
     padding: 8px 10px;

 }

 .resp-footer li a {
     width: 100%;
     display: block;
     text-align: left;
     padding-top: 8px;
     padding-bottom: 8px;
     padding-left: 8px;
     font-size: 14px;
     font-weight: 500px;
 }

 .resp-footer li div {
     width: 100%;
     display: block;
     text-align: left;
     padding-top: 8px;
     padding-bottom: 8px;
     padding-left: 8px;
     font-size: 14px;
     font-weight: 500;
 }

 .resp-footer {
     padding-top: 8px;
     padding-bottom: 8px;
 }

 .res-footer-main ul li {
     padding-bottom: 10px;
 }


 .wrapper {

     height: 65px;
     overflow-y: hidden;
     display: inline-block;

 }

 .text-anim {
     height: fit-content;
     animation: text-anaimation 5s ease-in-out infinite 0s;
     color: #4D30A6;

 }


 @keyframes head-1-animation {
     0% {
         opacity: 1;
     }

     10% {
         opacity: 1;

     }

     20% {
         opacity: 1;

     }

     30% {
         opacity: 1;

     }

     25% {
         opacity: 0;

     }

     40% {
         opacity: 0;

     }

     50% {
         opacity: 0;

     }

     60% {
         opacity: 0;

     }

     70% {
         opacity: 0;

     }

     80% {
         opacity: 0;

     }

     90% {
         opacity: 0;


     }

     100% {
         opacity: 1;


     }

 }

 @keyframes text-anaimation-responsive {
     0% {
         transform: translateY(0px);

     }

     10% {
         transform: translateY(0px);

     }

     20% {
         transform: translateY(0px);

     }

     30% {
         transform: translateY(-40px);

     }

     25% {
         transform: translateY(-40px);

     }

     40% {
         transform: translateY(-40px);

     }

     50% {
         transform: translateY(-40px);

     }

     60% {
         transform: translateY(-40px);

     }

     70% {
         transform: translateY(-40px);

     }

     80% {
         transform: translateY(0px);

     }

     90% {
         transform: translateY(0px);

     }

     100% {
         transform: translateY(0px);

     }

 }


 @keyframes text-anaimation {
     0% {
         transform: translateY(0px);

     }

     10% {
         transform: translateY(0px);

     }

     20% {
         transform: translateY(0px);

     }

     30% {
         transform: translateY(-65px);

     }

     25% {
         transform: translateY(-65px);

     }

     40% {
         transform: translateY(-65px);

     }

     50% {
         transform: translateY(-65px);

     }

     60% {
         transform: translateY(-65px);

     }

     70% {
         transform: translateY(-65px);

     }

     80% {
         transform: translateY(0px);

     }

     90% {
         transform: translateY(0px);

     }

     100% {
         transform: translateY(0px);

     }

 }

 .dash2 {
     animation-name: triangle-path-2;
     animation-delay: 2s;
 }

 .dash3 {
     animation-name: triangle-path-3;
     animation-delay: 4s;
 }

 .triangle {
     animation-duration: 6s;
     animation-iteration-count: infinite;
     animation-timing-function: ease-in-out;
 }

 .sectionA-banner {
     background: linear-gradient(90deg, #9297FF 0%, #C3AFFF 100%);
     border-radius: 16px;
     height: 100%;
     width: 100%;
     padding-top: 40px;
     padding-bottom: 40px;
 }

 .container {
     width: 85px;
     height: 60px;
     position: absolute;
     left: -180px;
     top: 0px;
     transform: scale(1);
     transition: transform 0.6s ease-in-out;

 }

 .container1 {
     width: 90px;
     height: 60px;
     position: absolute;
     left: -190px;
     bottom: 70px;
     transition: transform 0.6s ease-in-out;
     transform: scale(1);

 }

 .container2 {
     width: 90px;
     height: 60px;
     position: absolute;
     right: -200px;
     bottom: 70px;
     transition: transform 0.6s ease-in-out;
     transform: scale(1);

 }

 .container3 {
     width: 90px;
     height: 60px;
     position: absolute;
     right: -190px;
     top: -30px;
     transition: transform 0.6s ease-in-out;
     transform: scale(1);

 }

 .container.zoomed img {
     transition: transform 0.6s ease-in-out;
     transform: scale(3.1);
 }

 .zoomedOut img {
     transition: transform 0.6s ease-in-out;
     transform: scale(2.2);
 }

 .container1.zoomed img {
     transition: transform 0.6s ease-in-out;
     transform: scale(3.1);
 }

 .container2.zoomed img {
     transition: transform 0.6s ease-in-out;
     transform: scale(3.1);
 }

 .container3.zoomed img {
     transition: transform 0.6s ease-in-out;
     transform: scale(3.1);
 }

 .text-transition {
     display: flex;
     justify-content: center;
 }

 @keyframes triangle-path-1 {
     0% {
         left: 200px;
         top: 0;
         width: 150px;
         height: 93px;
     }

     25% {
         left: 100px;
         top: 187px;
         width: 150px;
         height: 93px;
     }



     50% {
         left: 0;
         top: 0;
         z-index: 40;
         width: 260px;
         height: 170px;
     }

     75% {
         left: 0;
         top: 0;
         z-index: 40;
         width: 260px;
         height: 170px;
     }

     100% {
         left: 200px;
         top: 0;
         width: 150px;
         height: 93px;
     }
 }

 @keyframes triangle-path-2 {
     0% {
         left: 200px;
         top: 0;
         width: 150px;
         height: 93px;
     }

     25% {
         left: 100px;
         top: 187px;
         width: 150px;
         height: 93px;
     }



     50% {
         left: 0;
         top: 0;
         z-index: 40;
         width: 260px;
         height: 170px;
     }

     75% {
         left: 0;
         top: 0;
         z-index: 40;
         width: 260px;
         height: 170px;
     }

     100% {
         left: 200px;
         top: 0;
         width: 150px;
         height: 93px;
     }
 }

 @keyframes triangle-path-3 {
     0% {
         left: 200px;
         top: 0;
         width: 150px;
         height: 93px;
     }

     25% {
         left: 100px;
         top: 187px;
         width: 150px;
         height: 93px;
     }



     50% {
         left: 0;
         top: 0;
         z-index: 40;
         width: 260px;
         height: 170px;
     }

     75% {
         left: 0;
         top: 0;
         z-index: 40;
         width: 260px;
         height: 170px;
     }

     100% {
         left: 200px;
         top: 0;
         width: 150px;
         height: 93px;
     }
 }

 @media (max-width: 768px) {
     .header-nav {
         display: none !important;
     }

     .responsive-btn {
         display: flex !important;
     }

 }

 @media (min-width: 768px) {
     .responsive-btn {
         display: none !important;
     }

     .resp-nav {
         display: none !important;
     }

     .header-nav {
         display: flex !important;
     }
 }


 @media (max-width: 768px) {
     h1 {
         font-weight: 600;
         font-size: 30px;
         line-height: 40px;
     }

     .text-anim {
         height: fit-content;
         animation: text-anaimation-responsive 5s ease-in-out infinite 0s;
         color: #4D30A6;
     }

     .wrapper {

         height: 40px;
         overflow-y: hidden;
         display: inline-block;

     }

     h2 {
         font-weight: 500;
         font-size: 24px;
         line-height: 36px;
         letter-spacing: 0.02em;
     }

     h3 {
         font-weight: 500;
         font-size: 22px;
         line-height: 30px;
     }


     .section-wraper {
         padding-left: 25px;
         padding-right: 25px;
     }

     .section-wraperA {
         padding-left: 25px;
         padding-right: 25px;
     }

     .sec-btn {
         font-weight: 600;
         font-size: 12px;
     }

     .section-para {
         font-weight: 400;
         font-size: 14px;
         line-height: 24px;
     }

     .sec1-banner {
         border: 0.322976px solid #000000;
         box-shadow: 3.22976px 3.22976px 0px #000000;
         border-radius: 10.3352px;

     }
 }

 @media (max-width:400px) {
     h1 {
         font-size: 24px;
         line-height: 38px;

     }

     .section-para {
         font-weight: 400;
         font-size: 13px;
         line-height: 16px;
     }
 }

 @media(max-width:1100px) {
     .card-avathar {
         position: absolute;
         right: 0 !important;
     }

     .dash-div {
         position: absolute;
         left: 10px !important;
     }
 }

 @media (max-width:1150px) {
     .dash1 {
         width: 225px;
         height: 150px;
     }

     .dash2 {
         width: 168px;
         height: 100px;
         right: -10px;
     }

     .dash3 {
         width: 190px;
         height: 140px;
         bottom: 60px !important;
     }


 }



 @media (max-width: 400px) {
     .wrapper {

         height: 60px;
         line-height: 30px;
         overflow-y: hidden;
         display: inline-block;
     }


     .head-1 {
         animation: head-1-animation 5s ease-in-out infinite 0s;

     }
 }

 @media (max-width: 500px) {
     .wrapper {

         height: 60px;
         line-height: 30px;
         overflow-y: hidden;
         display: inline-block;
     }

  
 }

 @media (max-width: 700px) {
     
    .successMsg {
       animation: showMsg1 1s ease-out;
       transition: .001s;
   }
}


 @media (max-width :1250px) {


     .container.zoomed img {
         transform: scale(2.3);
     }

     .container1.zoomed img {
         transform: scale(2.3);
     }

     .container2.zoomed img {
         transform: scale(2.3);
     }

     .container3.zoomed img {
         transform: scale(2.3);
     }


 }

 @media (max-width :1150px) {




     .container {

         position: absolute;
         left: -130px;
         top: 0px;
         transition: transform 0.6s ease-in-out;

     }

     .container1 {

         position: absolute;
         left: -130px;
         bottom: 70px;

     }

     .container2 {

         position: absolute;
         right: -130px;
         bottom: 70px;

     }

     .container3 {

         position: absolute;
         right: -130px;
         top: -30px;

     }


 }



 @media (max-width: 1024px) {
     .stat-head3 {
         font-size: 28px;
         line-height: 48px;

     }
 }