.footer_nav ul li a:hover {
    color: #3d38af
}

.footer_menu {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
    letter-spacing: 0.05em;
}

.footer_item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #262626;
}

.build {
    width: 100%;
    height: 423px;
    /* mix-blend-mode: soft-light; */
}

.digital {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 36px 10px;
    border: 0.2px solid #8A8A8A;
    border-radius: 40px;
}

.digitalA {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    border: 0.2px solid #8A8A8A;
    border-radius: 40px;
}

@media (max-width: 1024px) {
    .digital {
        padding: 24px 32px;
    }

    .digitalA {
        padding: 24px 32px;
    }
}

@media (max-width: 1024px) {
    .contact-banner {
        height: 500px;
    }
}

@media (max-width: 400px) {
    .contact-form {
        top: 50%;
        padding: 24px;
    }
}

@media (max-width: 768px) {
    .submit-btn {
        font-size: 10px;
    }
}

.learn {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #4D30A6;
}

.words {
    background: linear-gradient(90deg, #4953FF 0%, #47CA82 45.83%, #7345FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.see {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #4D30A6;
}

.cards {
    display: flex;
    flex-direction: column;
    background: #5234AF;
    border-radius: 24px;
    padding: 40px 37px 40px 37px;
    text-align: left;
    color: white;
}

.head {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    padding-top: 32px;
}

.inbox {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding-top: 24px;
}

@media (max-width: 768px) {
    .head {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        padding-top: 28px;
    }

    .inbox {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        padding-top: 21px;
    }
}

.signup-form {
    padding: 36px;
}

.signup-form input {
    border: 1px solid #dcd7d7;
    margin-bottom: 30px;
    outline: none;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
}

.signup-form input:hover {
    border: 1px solid #5929ea;
}

.signup-form input::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: #8B8B8B;
}

@media (max-width: 1024px) {
    .signup-form {
        width: 60%;
        padding: 20px;
    }

    .signIn {
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
        margin: auto;
    }
}

@media (max-width: 640px) {
    .signup-form {
        width: 100%;
    }
}

.signIn {
    border: 1px solid #dcd7d7;
    margin-top: 11px;
    outline: none;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
}

.signIn:hover {
    border: 1px solid #5929ea;
}

@media (max-width: 768px) {
    .build {
      background-image: none !important;
      background-color: white !important;
      height: 323px;

    }
}

.first {
    color: var(--color-primary, #4D30A6);
    font-family: General Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.firstP {
    color: var(--color-grey, #979797);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.18px;
}

@media (max-width: 1024px) {
    .first {
        color: var(--color-primary, #4D30A6);
        font-family: General Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .firstP {
        color: var(--color-grey, #979797);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.12px;
    }
}

/* @keyframes scroll {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media (min-width: 1024px) {
    .slide-track {
        animation: scroll 20s linear infinite running;
        animation-fill-mode: forwards;
        width: calc(100% + 250px);
        overflow: hidden;
    }
} */

/* @keyframes scroll {
    0% {
        transform: translateX(50%);
    }

    100% {
        transform: translateX(-50%);
    }
}

@media (min-width: 1024px) {
    .slide-track {
        animation: scroll 20s linear infinite running;
        animation-fill-mode: forwards;
        overflow: hidden;
    }
} */



.cover {
    border-radius: 24px;
    background: #FAFAFA;
    padding: 88px 30.875px 88px 44px;
}

.coverBox {
    border-radius: 12px;
    border: 1px solid var(--color-primary-200, #AF9FD6);
    background: #FFF;
    padding: 32px 40px;
    gap: 8px;
}

.coverBox:hover {
    border: 1px solid var(--color-primary-200, #AF9FD6);
    background: var(--color-primary-50, #EEEAF6);
}

.coverH {
    color: var(--color-black, #161616);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.coverP {
    color: var(--color-grey, #979797);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.coverBox.active {
    background: var(--color-primary, #4D30A6);
}

.coverBox.active .coverH,
.coverBox.active .coverP {
    color: #FFF;
}

.toggle-container {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.9s ease-in-out;
}

.toggle-container.visible {
    max-height: 500px;
}

.content {
    border-radius: 0px 0px 13.108px 13.108px;
    background: #FAFAFA;
    height: 500px;
    padding: 48.064px 16.863px 48.064px 24.032px;
}

.solution {
    border-radius: 24px;
    background: #F5F7FF;
    padding: 48px 80px;
}

.solutionTab {
    border-radius: 12px;
    background: #FEFEFF;
    padding: 16px 38px;
    color: var(--color-primary, #4D30A6);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.solutionImg {
    border-radius: 24px 24px 24px 24px;
    border: 1px solid transparent;
}

.solutionTab:hover {
    background: var(--color-primary-50, #EEEAF6);
}

.solutionTab.active {
    background: var(--color-primary, #4D30A6);
    color: #FFF;
}

@media (max-width: 1024px) {
    .solutionTab {
        border-radius: 8px;
        border: 1px solid var(--color-primary, #4D30A6);
    }

    .solution {
        padding: 48px 10px;
    }
}

@media (max-width: 640px) {
    .solutionTab {
        font-size: 16px;
        font-weight: 600;
    }
}